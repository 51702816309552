const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 添加采购商员工接口文档地址：http://219.128.52.2:9999/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/user-buyer-controller/addNewBuyerEmployeeUsingPOST
const web_userBuyer_addBuyerEmployee = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/web/userBuyer/addBuyerEmployee',
    data: pParameter,
    //
  };

  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    });


};
module.exports = web_userBuyer_addBuyerEmployee;
