// 员工添加
import Q from 'q';
// 添加员工接口
import web_userBuyer_addBuyerEmployee from '@/lib/data-service/default/web_userBuyer_addBuyerEmployee';
// 获取权限列表
import web_common_source_getPermissions from '@/lib/data-service/default/web_common_source_getPermissions';
// 验证账号名是否已存在
import web_common_user_vueCheckUserName from '@/lib/data-service/default/web_common_user_vueCheckUserName';
import limits from '../../component/limits';
import is_mobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
export default {
  data() {
    const reg = /[0-9a-zA-Z]/g;
    var validateUserName = (rule, value, callback) => {
      if (value === undefined || value === '') {
        callback(new Error("请输入账号"));
      } else {
        if (value.length < 4) {
          callback(new Error("最少要输入4个字符"));
        } else if (value.length > 16) {
          callback(new Error("最多可输入16个字符"));
        } else if (!reg.test(value)) {
          callback(new Error("仅限使用数字与字母"))
        } else {
          const _this = this;
          Q.when()
            .then(function () {
              return _this.verify_userName(value);
            })
            .then(function (res) {
              if (res.flag === true) {
                callback();
              } else if (res.flag === false) {
                callback(new Error('账号已存在'))
              }
            })
        }
      };
    }


    var validateVerify1 = (rule, value, callback) => {
      if (value === '' || value === undefined) {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 6) {
          callback(new Error("最少输入6个字符"));
        } else if (value.length > 20) {
          callback(new Error("最多可输入20个字符"));
        } else if (this.reg_string(value)) {
          callback(new Error("仅限使用数字与字母"));
        } else {
          callback();
        }
      }
    };




    var validateVerify = (rule, value, callback) => {
      if (value === undefined || value === '') {
        callback(new Error("请确认密码"));
      } else {
        if (value !== this.form.password) {
          callback(new Error("两次密码不一致"));
        }
        callback();
      }
    }

    var validatePhone = (rule, value, callback) => {
      if (is_mobile(value)) {
        callback()
      } else {
        callback(new Error('不符合手机号码格式'))
      }
    }
    return {
      form: {
        userName: '',
        password: '',
        verifyPassword: '',
        realName: '',
        phone: '',
        qq: '',
        eMail: '',
        resourceIds: [],
      },//
      formRules: {
        userName: [
          { required: true, validator: validateUserName, trigger: 'blur' }
        ],
        password: [
          { validator: validateVerify1, trigger: "blur", required: true },
        ],
        verifyPassword: [
          { validator: validateVerify, trigger: 'blur', required: true }
        ],
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { min: 2, message: '最少输入2个字符', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur', required: true }
        ],
        qq: [
          { pattern: /^[1-9]\d{4,10}$/, message: '请输入正确的QQ号码', trigger: 'blur' },
        ],
        eMail: [
          { pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: '请输入正确的邮箱', trigger: 'blur' }
        ]
      },
      authorityList: [],
    };
  },
  props: {
    statu: Number
  },
  methods: {
    req_add_staff(params) {
      return Q.when()
        .then(function () {
          return web_userBuyer_addBuyerEmployee(params);
        })
    },
    req_get_authority_list() {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_source_getPermissions();
        })
        .then(function (res) {
          const authorityList = res.permissionList;
          let oneLevel
          oneLevel = authorityList.filter(item => {
            return item.resourceLevel === '2'
          })
          oneLevel.map(item => {
            let arr = authorityList.reduce(function (newArr, sItem) {
              if (sItem.parentId === item.resourceId) {
                newArr.push(sItem);
              }
              return newArr;
            }, []);
            item.childList = arr
          })
          _this.getAuthoritySrot(oneLevel)
        })
    },
    getAuthoritySrot(authorityList) {
      let arr = []
      Object.values(limits).map((key, index) => {
        authorityList.map(item => {
          if (item.permission === key) {
            arr.push(item)
          }
        })
      })
      this.authorityList = arr
    },
    req_verify_userName(params) {
      return Q.when()
        .then(function () {
          return web_common_user_vueCheckUserName(params);
        })
    },
    initialize() {
      this.req_get_authority_list();
    },
    verify_userName(userName) {
      const _this = this;
      return Q.when()
        .then(function () {
          return _this.req_verify_userName({ userName });
        })
    },
    checkAll_authorityItem(checked, list) {
      checked = !checked
      setCheck(list, !checked)
      function setCheck(list, status) {
        list.map(item => {
          item.checked = status
        })
      }
      this.$forceUpdate()
    },
    check_item(list) {
      let itemList = list.childList
      let statu = itemList.map(item => {
        return item.checked
      })
      statu.indexOf(true) === -1 ? list.checked = false : list.checked = true
    },
    commit_add_staff(form) {
      const _this = this;
      this.$refs[form].validate((valid) => {
        if (valid) {

          console.log('3333')
          Q.when()
            .then(function () {
              return _this.req_add_staff(_this.form);
            })
            .then(function () {
              _this.$message({
                message: '员工账号添加成功',
                type: 'success'
              });
              _this.req_get_authority_list()
              _this.form = {};
              this.$forceUpdate()
            })
        } else {
          return false;
        }
      })
    },
    to_back() {
      this.$router.push({
        name: 'distributor-tour-back-staff-management',
      })
      document.documentElement.scrollTop = 0;
    },
    reg_string (str) {
      const reg1 = /\d/;
      const reg2 = /[a-zA-Z]/;
      const arr = str.split('');
      for (let i = 0; i < arr.length; i++) {
        const str = arr[i];
        if (!reg1.test(str) && !reg2.test(str)) return true;
      }
    },
  },
  watch: {
    authorityList: {
      handler (val) {
        const form = this.form;
        const resourceIds = [];
        for (let i = 0; i < val.length; i++) {
          const childList = val[i].childList;
          for (let j = 0; j < childList.length; j++) {
            const child = childList[j];
            if (child.checked) {
              const resourceId = `${child.parentId}-${child.resourceId}`;
              resourceIds.push(resourceId);
            }
          }
        }
        form.resourceIds = resourceIds;
      },
      deep: true,
    }
  },
  mounted() {
  },
  activated() {
    this.initialize();
  }
};
